import { Component } from '@angular/core';
import { Media, Maybe, MediaForMediaListFragment } from '@designage/gql';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../modals/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule, ConfirmDialogComponent, TranslatePipe, NgbTooltip],
  selector: 'app-delete-media-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    headerText="DELETE_MEDIA_LIST"
    [closeButtonText]="'PROCEED'"
    [dismissButtonText]="'CANCEL'"
  >
    <div class="card mb-3">
      <h5
        *ngIf="isPermanentDelete; else isMoveToTrashHeader"
        class="card-header bg-danger"
      >
        {{
          isDeleteAllSelected
            ? ('DELETE_MEDIA_PROMPT_SELECTED' | translate)
            : ('DELETE_MEDIA_PROMPT' | translate)
        }}
      </h5>

      <ng-template #isMoveToTrashHeader>
        <h5 class="card-header bg-warning">
          {{ 'MOVE_MEDIA_TO_TRASH_PROMPT' | translate }}
        </h5>
      </ng-template>
    </div>

    <div class="p-2">
      <div class="vstack" *ngFor="let media of selectedMList">
        <span class="text-truncate fs-6" [ngbTooltip]="media.name">
          {{ media.name }}
        </span>
        <hr />
      </div>
    </div>

    <div *ngIf="!isPermanentDelete" class="card">
      <h5 class="card-header bg-secondary">
        {{ 'TRASH_FOLDER_INFO_HEADER' | translate }}
      </h5>
      <div class="alert alert-danger">
        {{ 'TRASH_FOLDER_INFO_PROMPT_2' | translate }}
      </div>
      <!-- <div class="p-2">{{ 'TO_TRASH_FOLDER_PROMPT' | translate }}</div> -->
      <div class="p-2">{{ 'TRASH_FOLDER_INFO_PROMPT_1' | translate }}</div>
    </div>
  </app-confirm-dialog> `,
})
export class DeleteMediaDialogComponent {
  media?: Maybe<Media>;
  isDeleteAllSelected?: boolean;
  selectedMList?: MediaForMediaListFragment[];
  isPermanentDelete: boolean = true;
}
