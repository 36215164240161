import { Injectable, inject, signal } from '@angular/core';
import {
  DeleteMediaListGQL,
  GetMediaDetailedByIdsGQL,
  GetMediaForMediaManagePageGQL,
  GetMediaListGQL,
  GetProfileMediaForMediaListGQL,
  GetStorageSummaryGQL,
  Maybe,
  Media,
  MediaDetailedFragment,
  MediaForMediaListFragment,
  MediaVisibilityType,
  MoveMediaFolderGQL,
  MoveMediaFolderInput,
  ResourceType,
  SaveMediaFromCloudGQL,
  SaveMediaInput,
  StorageSummaryType,
} from '@designage/gql';
import { DeleteMediaDialogComponent } from '@desquare/components/common/src/media/delete-media-dialog/delete-media-dialog.component';
import { MediaReadableType } from '@desquare/enums';
import {
  IMediaFilterButtonGroupOutput,
  IMediaForMediaList,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, filter } from 'lodash';
import { Observable, lastValueFrom, map, take, tap } from 'rxjs';
import { FilterService } from '../filter/filter.service';
import { ToasterService } from '../toaster/toaster.service';
import { lazyLoadService } from '@desquare/utils';
import { GraphQLFormattedError } from 'graphql';
import { sessionProfile } from '@desquare/services';

@Injectable({
  providedIn: 'root',
  // note: using providedIn: 'any', makes this service's instance to be
  // shared among all the eagerly loaded modules
  // more info: https://stackoverflow.com/a/72561645
})
export class MediaService {
  DEFAULT_MEDIA_VISIBILITY_FILTER: MediaVisibilityType[] = [
    MediaVisibilityType.Default,
    MediaVisibilityType.Template,
    MediaVisibilityType.Playlist,
  ];
  //  sources
  getMediaListGql = inject(GetMediaListGQL);
  getMediaListGqlDoc = this.getMediaListGql.document;
  getMediaListGql$ = this.getMediaListGql.watch(
    {},
    {
      fetchPolicy: 'network-only',
    },
  );

  private folderService$ = lazyLoadService(() =>
    import('../folder/folder.service').then((m) => m.FolderService),
  );

  folderIds = signal<string[]>([]);

  private getStorageSummaryGql = inject(GetStorageSummaryGQL);

  async getStorageSummary(types: StorageSummaryType[]) {
    const id = sessionProfile()?.id;

    if (!!id) {
      const { data } = await lastValueFrom(
        this.getStorageSummaryGql.fetch(
          { id, types },
          {
            fetchPolicy: 'network-only',
          },
        ),
      );
      return cloneDeep(data.profile?.storage) || [];
    }
    return [];
  }

  constructor(
    private getMediaGQL: GetMediaForMediaManagePageGQL,
    private getMediaDetailedByIdsGql: GetMediaDetailedByIdsGQL,
    private getProfileMediasGQL: GetProfileMediaForMediaListGQL,
    private saveMediaGQL: SaveMediaFromCloudGQL,
    private deleteMediaListGQL: DeleteMediaListGQL,
    private moveMediaFolderGQL: MoveMediaFolderGQL,
    private filterService: FilterService,
    private toasterService: ToasterService,
    // private folderService: FolderService,
    private modalService: NgbModal,
  ) {}

  getProfileMediasFromApi$(): Observable<{
    media: Media[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.getMediaListGql$.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        if (!data) return { media: [], loading, errors };
        const media = data?.medias as Media[];

        return { media, loading, errors };
      }),
    );
  }

  refetchMedia() {
    this.getMediaListGql$.refetch();
  }

  async getMediaById(id: string) {
    const { data } = await lastValueFrom(
      this.getMediaGQL.fetch(
        {
          id,
        },
        { fetchPolicy: 'no-cache' },
      ),
    );
    return data?.media as Maybe<MediaDetailedFragment>;
  }

  async getMediaByIds(ids: string[]) {
    const { data } = await lastValueFrom(
      this.getMediaDetailedByIdsGql.fetch({
        ids,
      }),
    );
    return data?.medias as Maybe<MediaDetailedFragment[]>;
  }

  async getProfileMediaForCreativeEditor(profileId: string, groupId?: string) {
    const { data } = await lastValueFrom(
      this.getProfileMediasGQL.fetch(
        {
          profileId,
          filters: {
            pageSize: 500,

            // if the folderId (group) is 'root' then we get the medias in root
            // note: medias that have the folderId === null are considered in root
            folderId: groupId === 'root' ? null : groupId,
          },
        },
        {
          fetchPolicy: 'no-cache',
          // note: using cache causes getting stale data here, to reproduce staleness:
          // 1. move media to a different folder
          // 2. open profile images in creative editor
        },
      ),
    );

    return data.profile?.media.results as Maybe<Media[]>;
  }

  filterMedias({
    medias,
    searchText,
    filterList,
  }: {
    medias: IMediaForMediaList[];
    searchText?: string;
    filterList?: IMediaFilterButtonGroupOutput[];
  }) {
    // filter by media filter button group
    const filteredMedias: IMediaForMediaList[] =
      !filterList || filterList.length === 0
        ? medias
        : filterList?.flatMap((filterOutput) => {
            let partialMedia: Partial<IMediaForMediaList> = {};
            if (filterOutput?.mediaVisibility)
              partialMedia.visibility = filterOutput.mediaVisibility;
            if (filterOutput?.resourceType)
              partialMedia.type = filterOutput.resourceType;

            // lodash filter(): https://lodash.com/docs/4.17.15#filter
            // combine the filtered list into one list
            return filter(medias, partialMedia);
            // warning: this logic could potentially have duplicates
            // needs thorough testing, if it does happen we can
            // simply add a function after this filter logic that
            // removes duplicates
          });

    // filter by search input field
    medias = this.filterService.filterListByName(
      searchText ?? '',
      filteredMedias,
    );

    return medias;
  }

  /**
   * Computed property of Media, derived from media.visibility and
   * media.resourceType
   *
   * @param media
   * @returns string
   */
  getReadableType(media: MediaForMediaListFragment) {
    if (
      media.visibility === MediaVisibilityType.Default &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.IMAGE;
    } else if (
      media.visibility === MediaVisibilityType.Default &&
      media.type === ResourceType.Video
    ) {
      return MediaReadableType.VIDEO;
    } else if (
      media.visibility === MediaVisibilityType.Template &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.IMAGE_TEMPLATE;
    } else if (
      media.visibility === MediaVisibilityType.Template &&
      media.type === ResourceType.Video
    ) {
      return MediaReadableType.VIDEO_TEMPLATE;
    } else if (
      media.visibility === MediaVisibilityType.Playlist &&
      media.type === ResourceType.Image
    ) {
      return MediaReadableType.PLAYLIST_IMAGE;
    } else if (
      media.visibility === MediaVisibilityType.Playlist &&
      media.type === ResourceType.Video
    ) {
      return MediaReadableType.PLAYLIST_VIDEO;
    } else {
      // if non of the combinations above
      return MediaReadableType.UNKNOWN;
    }
  }

  /**
   * Saves media to our database, usually after uploading
   * the media to cloudinary hence the input is similar to
   * the shape of the cloudinary response.
   * - runs refetchQueries()
   *
   * @param input - ISaveMediaInput
   * @returns
   */
  saveMedia(input: SaveMediaInput) {
    return this.saveMediaGQL.mutate(
      {
        input,
      },
      {
        refetchQueries: [this.getMediaListGqlDoc],
        awaitRefetchQueries: true,
      },
    );
  }

  /**
   * Deletes medias by list of ids.
   * After doing the mutation this function does the following:
   * - refetches all the media queries
   * - shows a toaster
   *
   * @param ids - list of media ids
   * @returns observable for the mutation
   */
  deleteMedia(ids: string[]) {
    return this.deleteMediaListGQL
      .mutate(
        { ids },
        {
          refetchQueries: [this.getMediaListGqlDoc],
          awaitRefetchQueries: true,
        },
      )
      .pipe(
        map(async (result) => {
          const { data, errors } = result;
          if (data?.deleteMediaList.isSuccessful) {
            // TODO (04-27-2023): go back and fix the refetches below later
            // we recently figured out a better way to refetch

            // refetch
            this.refetchMedia();

            // refresh folder list
            this.refetchFolderServiceQueries();

            this.toasterService.clear();
            this.toasterService.success('DELETE_MEDIA_SUCCESS');

            return result;
          } else {
            // TODO: properly handle errors
            console.error(errors);
            return undefined;
          }
        }),
      );
  }

  /**
   * Moves medias to the folder using their ids.
   * After doing the mutation this function does the following:
   * - refetches all the media queries
   * - shows a toaster
   *
   * @param folderId - id of the folder where medias will be moved to
   * @param mediaIds - id of the medias that will be moved
   * @returns
   */
  moveMediaToFolder({ folderId, mediaIds }: MoveMediaFolderInput) {
    return this.moveMediaFolderGQL
      .mutate(
        { input: { folderId, mediaIds } },
        {
          refetchQueries: [this.getMediaListGqlDoc],
          awaitRefetchQueries: true,
        },
      )
      .pipe(
        map(async (result) => {
          const { data, errors } = result;

          if (data?.moveMediaFolder?.isSuccessful) {
            // this.datatableLoading = false;
            this.toasterService.clear();
            this.toasterService.success('MOVE_MEDIA_FOLDER_SUCCESS');
            return result;
          } else {
            // TODO: properly handle errors
            console.error(errors);
            return undefined;
          }
        }),
      );
  }

  /**
   * Opens a modal to confirm deleting the media. Depending on where the media(s)
   * it will either:
   * 1) move the media(s) to trash
   * 2) delete the media permanently
   *
   * It will choose (2) if the media(s) are under the trash folder already
   *
   * @param mediaList - media(s) that will be deleted or moved to trash
   * @param mediaFolderId - the folder where the media(s) are in
   * @returns
   */
  async openDeleteMediaDialog({
    mediaList,
    mediaFolderId,
  }: {
    mediaList: Media[];
    mediaFolderId: string | null;
  }) {
    const ids = mediaList.map((x) => x.id);

    const modal = this.modalService.open(DeleteMediaDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.selectedMList = mediaList;
    modal.componentInstance.isDeleteAllSelected = mediaList.length > 1;

    // get folder service
    const folderService = await lastValueFrom(
      this.folderService$.pipe(take(1)),
    );

    // get or create trash folder
    const trashFolder = await folderService.getTrashFolder();
    if (!trashFolder) return;

    // check ancestor folders if the current selected folder is in
    // the trash folder
    const ancestorFolders =
      await folderService.getFullPathListById(mediaFolderId);
    const isInTrashFolder = ancestorFolders?.find(
      ({ id }) => id === trashFolder.id,
    )
      ? true
      : false;

    modal.componentInstance.isPermanentDelete = isInTrashFolder;

    await modal.result
      .then(async () => {
        // if media(s) are in the trash folder
        // then permanently delete media
        if (isInTrashFolder) {
          await lastValueFrom(this.deleteMedia(ids));
        } else {
          // if media(s) are not in the trash folder
          // then move media(s) to the trash folder
          await lastValueFrom(
            this.moveMediaToFolder({
              folderId: trashFolder.id,
              mediaIds: ids,
            }),
          );
        }
      })
      .catch(() => {});
  }

  refetchFolderServiceQueries() {
    this.folderService$
      .pipe(
        take(1),
        tap((folderService) => {
          folderService.refetchQueries();
        }),
      )
      .subscribe();
  }

  setFolderIds(folderIds: string[]) {
    this.folderIds.set(folderIds);
  }
}
